<script>
import {profileComputed, messagesAiComputed} from "@/state/helpers";
import chat from "../views/pages/chat/index.vue";
import ChatLoader from "@/components/Partials/ChatLoader";

export default {
	name: "RoomChatMessages",
	components: {
		ChatLoader
	},
	props: {
		chatRoom: {
			type: Object,
			required: true
		},
		messages: {
			type: Array,
			required: true,
		},
	},
	computed: {
		...profileComputed,
		...messagesAiComputed,
		chat() {
			return chat
		}
	},
	methods: {

	},

}
</script>

<template>
	<div v-if="messages.length">
		<div id="mess-list">
			<ul
				id="messages-list"
				class="mess-list-unst list-unstyled mb-0"
			>
				<li
					:class="{ right: item.type === 0 }"
					v-for="(item, index) in messages" :key="index"
				>
					<div
						class="conversation-list"
						ref="imageRef"
					>
						<div class="chat-avatar">
							<img v-if="userInfo.image" :src="userInfo.image" class="avatar-sm" alt="">
							<div v-else class="avatar-sm">
								<span class="avatar-title rounded-circle bg-soft-primary text-primary">
									{{ (item.type === 0 ? chatRoom.name : userInfo.first_name).charAt(0) }}
								</span>
							</div>
						</div>
						<div class="user-chat-content">
							<div class="ctext-wrap">
								<div class="ctext-wrap-content">
									<p>{{ item.message }}</p>
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
			<ChatLoader v-if="loadingMessages" />
		</div>
	</div>
</template>
