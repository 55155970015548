<script>
import vClickOutside from "v-click-outside";
import RoomChatMessages from './RoomChatMessages'
import {messagesAiComputed, profileMethods, notificationMethods} from "@/state/helpers";
import {required} from "vuelidate/lib/validators";
import Loader from "@/components/Partials/Loader.vue";
import router from "@/router";

export default {
	name: "RoomChat",
	components: {
		RoomChatMessages,
		Loader
	},
	directives: {
		clickOutside: vClickOutside.directive,
	},
	props: {
		chatRoom: {
			type: Object,
			required: true
		},
		currentChatRoomId: {
			type: Number
		}
	},
	data() {
		return {
			message: "",
			isProfileEdit: false,
			showModal: false,
			submitted: false,
			chatInfoForm: {
				id: '',
				name: '',
				gender: '',
				age: '',
				city: '',
				hobby: '',
				personality: '',
				nationality: '',
				study: '',
				occupation: '',
				dream: '',
				imageFile: null,
				imagePreview: null,
				imageError: null,
			}
		}
	},
	validations: {
		chatInfoForm: {
			name: {required},
		}
	},
	computed: {
		...messagesAiComputed,
		inputDisabled() {
			return this.isMessageEmpty();
		},
		notification() {
			return this.$store ? this.$store.state.notification : null;
		},
		notificationAutoCloseDuration() {
			return this.$store && this.$store.state.notification ? 5 : 0;
		},
	},
	methods: {
		...profileMethods,
		...notificationMethods,
		closeUserChat() {
			let userChat = document.getElementsByClassName("user-chat");
			if (userChat) {
				userChat[0].classList.remove("user-chat-show");
			}
		},
		toggleProfile() {
			this.prepareData();
			document.getElementById("profile-show").style.display = "block";
		},
		hideProfile() {
			document.getElementById("profile-show").style.display = "none";
		},
		isMessageEmpty() {
			return !this.message.trim();
		},
		resizeTextarea() {
			console.log('resize')
			// const el = this.$refs["roomTextarea"];
			// if (!el) return;
			// const padding = window
			// 	.getComputedStyle(el, null)
			// 	.getPropertyValue("padding-top")
			// 	.replace("px", "");
			// el.style.height = 0;
			// el.style.height = el.scrollHeight - padding * 2 + "px";
		},
		onChangeInput() {
			this.resizeTextarea();
			// this.$emit("typingMessage", this.message);
		},
		resetMessage() {
			console.log('reset mess');
			this.message = ''
		},
		handleImageUpload(event) {
			const file = event.target.files[0];
			const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];

			// Reset previous errors and file preview
			this.chatInfoForm.imageError = null;
			this.chatInfoForm.imagePreview = null;
			this.chatInfoForm.imageFile = null;

			if (file) {
				// Validate file type
				if (!validTypes.includes(file.type)) {
					this.chatInfoForm.imageError = "Invalid file type. Only JPEG, PNG, JPG, and GIF are allowed.";
					return;
				}

				// Store the file
				this.chatInfoForm.imageFile = file;

				// Preview the image
				this.chatInfoForm.imagePreview = URL.createObjectURL(file);
			}
		},
		updateChatInfo() {
			this.submitted = true;
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			} else {
				const formData = new FormData();
				formData.append("id", this.chatInfoForm.id);
				formData.append("gender", this.chatInfoForm.gender);
				formData.append("name", this.chatInfoForm.name);
				formData.append("age", this.chatInfoForm.age || '');
				formData.append("city", this.chatInfoForm.city || '');
				formData.append("hobby", this.chatInfoForm.hobby || '');
				formData.append("personality", this.chatInfoForm.personality || '');
				formData.append("nationality", this.chatInfoForm.nationality || '');
				formData.append("study", this.chatInfoForm.study || '');
				formData.append("occupation", this.chatInfoForm.occupation || '');
				formData.append("dream", this.chatInfoForm.dream || '');

				if (this.chatInfoForm.imageFile) {
					formData.append("image", this.chatInfoForm.imageFile);
				}
				this.isProfileEdit = false;
				this.$store.dispatch('messageState/updateChatRoom', formData, this.chatInfoForm.id);
			}
		},
		prepareData() {
			this.chatInfoForm.id = this.chatRoom.id;
			this.chatInfoForm.gender = this.chatRoom.gender;
			this.chatInfoForm.name = this.chatRoom.name;
			this.chatInfoForm.age = this.chatRoom.age;
			this.chatInfoForm.city = this.chatRoom?.city;
			this.chatInfoForm.hobby = this.chatRoom.hobby;
			this.chatInfoForm.personality = this.chatRoom.personality;
			this.chatInfoForm.nationality = this.chatRoom.nationality;
			this.chatInfoForm.study = this.chatRoom.study;
			this.chatInfoForm.occupation = this.chatRoom.occupation;
			this.chatInfoForm.dream = this.chatRoom.dream;
		},
		sendMessage() {
			this.hideProfile()
			if (this.message.trim() === ''){
				return
			}
			const data = {
				chatId: this.activeRoomId,
				message: this.message
			}
			this.$store.dispatch('messageState/sendMessage', data);
			this.message = ''
		},
		async deleteChat() {
			await this.$store.dispatch('messageState/deleteChatRoom', this.activeRoomId);
			await this.$store.dispatch('profile/getUserInfo');
		},
		unlockCurrentChat() {
			router.push('/checkout')
		}
	},
}
</script>

<template>
	<div class="user-chat w-100 overflow-hidden">
		<div
			v-if="chatRoom.is_free_chat"
			class="d-lg-flex"
		>
			<div class="w-100 overflow-hidden position-relative">
				<div class="user-chat-header p-3 p-lg-4 border-bottom">
					<div class="row align-items-center justify-content-between">
						<div :class="notification.message ? 'col-sm-2 col-2' : 'col-sm-6 col-6'">
							<div class="media align-items-center">
								<div class="d-block d-lg-none mr-2">
									<a
										href="#"
										@click.prevent="closeUserChat()"
										class="user-chat-remove text-muted font-size-16 p-2"
									>
										<i class="ri-arrow-left-s-line"></i>
									</a>
								</div>
								<div class="mr-3">
									<img v-if="chatRoom.image" :src="chatRoom.image" class="avatar-sm image-ava-single" alt="">
									<div v-else class="avatar-sm">
										<span class="avatar-title rounded-circle bg-soft-primary text-primary">
											{{ (chatRoom.name).charAt(0) }}
										</span>
									</div>
								</div>
								<div class="media-body overflow-hidden">
									<h5 class="font-size-16 mb-0 text-truncate">
										<a href="javascript:void(0);" class="text-reset user-profile-show">
											{{ chatRoom.name }}
										</a>
										<i
											class="ri-record-circle-fill font-size-10 text-success d-inline-block ml-1"
										></i>
									</h5>
								</div>
							</div>
						</div>
						<div :class="notification.message ? 'col-sm-6 col-6' : 'col-sm-2 col-2'">
							<b-alert
								:variant="notification.type"
								v-if="notification.message"
								:show="notificationAutoCloseDuration"
								dismissible
							>{{ notification.message }}
							</b-alert>
						</div>
						<div class="col-sm-3 col-3">
							<ul class="list-inline user-chat-nav text-right mb-0">
								<li class="list-inline-item d-none d-lg-inline-block">
									<button
										type="button"
										class="btn nav-btn user-profile-show"
										@click="toggleProfile()"
									>
										<i class="ri-user-2-line"></i>
									</button>
								</li>

								<li class="list-inline-item">
									<b-dropdown toggle-class="nav-btn" variant="white" right>
										<template v-slot:button-content>
											<i class="ri-more-fill"></i>
										</template>
										<b-dropdown-item
											class="d-block d-lg-none user-profile-show"
											@click="toggleProfile()"
										>
											View profile
											<i class="ri-user-2-line float-right text-muted"></i>
										</b-dropdown-item>
										<b-dropdown-item @click="showModal = !showModal">
											Delete
											<i class="ri-delete-bin-line float-right text-muted"></i>
										</b-dropdown-item>
									</b-dropdown>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div
					class="chat-conversation p-3 p-lg-4"
					id="containerElement"
					ref="current"
				>
					<loader v-if="loadingRoom" />
					<RoomChatMessages v-else :chatRoom="chatRoom" :messages="currentRoomMessages"/>
					<div id="mess-end"></div>
				</div>
				<div ref="roomFooter" class="room-footer">
					<div class="d-flex align-items-center box-footer chat-input-section p-3 p-lg-4 border-top mb-0">
						<textarea
							ref="roomTextarea"
							:placeholder="'Enter ' + chatRoom.name + ' Message...'"
							class="form-control form-control-lg bg-light border-light rounded"
							style="max-height: 45px; height: 100%;resize: none;"
							v-model="message"
							:disabled="loadingMessages"
							@input="onChangeInput"
							@keydown.esc="resetMessage"
							@keyup.enter="sendMessage"
							@keydown.enter.exact.prevent
						></textarea>
						<div class="icon-textarea">
							<div>
								<button
									type="submit"
									class="btn btn-primary font-size-16 btn-lg chat-send"
									@click="sendMessage"
									:disabled="inputDisabled"
									:class="{ 'send-disabled': inputDisabled }"
								>
									<i
										class="ri-send-plane-2-fill"
									></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="user-profile-sidebar" id="profile-show">
				<div class="px-2 px-lg-2 pt-2 pt-lg-2">
					<div class="user-chat-nav text-right">
						<button
							type="button"
							class="btn nav-btn"
							id="user-profile-hide"
							@click="hideProfile"
						>
							<i class="ri-close-line"></i>
						</button>
					</div>
				</div>
				<div class="text-center border-bottom">
					<div class="mb-4 profile-user">
						<img v-if="chatRoom.image" :src="chatRoom.image" class="avatar-md image-ava-single" alt="">
						<div v-else class="avatar-md m-auto">
							<span class="avatar-title rounded-circle bg-soft-primary text-primary">
								{{ (chatRoom.name).charAt(0) }}
							</span>
						</div>
						<button
							type="button"
							class="btn bg-light avatar-xs p-0 rounded-circle profile-photo-edit"
							@click="isProfileEdit = !isProfileEdit"
						>
							<i class="ri-pencil-fill"></i>
						</button>
					</div>
					<h5 class="font-size-16 mb-1 text-truncate">
						{{ chatRoom.name }}
					</h5>
				</div>
				<!-- End profile user -->

				<!-- Start user-profile-desc -->
				<div class="user-profile-desc p-4">
					<div>
						<form @submit.prevent="updateChatInfo">
							<div class="form-group mb-4">
								<b-row>
									<b-col>
										<div v-if="isProfileEdit" class="form-group">
											<label for="imageUpload">Image</label>
											<input
												type="file"
												id="imageUpload"
												class="form-control"
												@change="handleImageUpload"
												accept="image/jpeg, image/png, image/jpg, image/gif"
											/>
<!--											<div v-if="chatInfoForm.imagePreview" class="image-preview mt-3">-->
<!--												<img :src="chatInfoForm.imagePreview" alt="Image Preview" class="img-thumbnail" />-->
<!--											</div>-->
											<div v-if="chatInfoForm.imageError" class="invalid-feedback">{{ chatInfoForm.imageError }}</div>
										</div>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<label for="updateName-input">{{
												$t("chat.tabs.settings.modal.form.name.label")
											}}</label>
										<input
											type="text"
											class="form-control"
											id="updateName-input"
											:placeholder=" $t('chat.tabs.settings.modal.form.name.placeholder')"
											v-model="chatInfoForm.name"
											:disabled="!isProfileEdit"
											:class="{
													  'is-invalid': submitted && $v.chatInfoForm.name.$error,
													}"
										/>
										<div
											v-if="submitted && $v.chatInfoForm.name.$error"
											class="invalid-feedback"
										>
												<span v-if="!$v.chatInfoForm.name.required">{{
														$t("chat.tabs.settings.modal.form.name.validation.required")
													}}</span>
										</div>
									</b-col>
									<b-col>
										<label for="updateName-input">Age</label>
										<input
											type="number"
											class="form-control"
											id="updateName-input"
											placeholder="Age"
											v-model="chatInfoForm.age"
											:disabled="!isProfileEdit"
											min="15"
											max="100"
										/>
									</b-col>
								</b-row>
							</div>
							<div class="form-group mb-4">
								<b-row>
									<b-col>
										<label for="updateName-input">City</label>
										<input
											type="text"
											class="form-control"
											id="updateName-input"
											placeholder="City"
											v-model="chatInfoForm.city"
											:disabled="!isProfileEdit"
										/>
									</b-col>
									<b-col>
										<label for="updateName-input">Hobby/Interest</label>
										<input
											type="text"
											class="form-control"
											id="updateName-input"
											placeholder="Hobby/Interest"
											v-model="chatInfoForm.hobby"
											:disabled="!isProfileEdit"
										/>
									</b-col>
								</b-row>
							</div>
							<div class="form-group mb-4">
								<b-row>
									<b-col>
										<label for="updateName-input">Personality</label>
										<input
											type="text"
											class="form-control"
											id="updateName-input"
											placeholder="Interests"
											v-model="chatInfoForm.personality"
											:disabled="!isProfileEdit"
										/>
									</b-col>
									<b-col>
										<label for="updateName-input">Nationality</label>
										<input
											type="text"
											class="form-control"
											id="updateName-input"
											placeholder="Nationality"
											v-model="chatInfoForm.nationality"
											:disabled="!isProfileEdit"
										/>
									</b-col>
								</b-row>
							</div>
							<div class="form-group mb-4">
								<b-row>
									<b-col>
										<label for="updateName-input">Study</label>
										<input
											type="text"
											class="form-control"
											id="updateName-input"
											placeholder="Study"
											v-model="chatInfoForm.study"
											:disabled="!isProfileEdit"
										/>
									</b-col>
									<b-col>
										<label for="updateName-input">Occupation</label>
										<input
											type="text"
											class="form-control"
											id="updateName-input"
											placeholder="Occupation"
											v-model="chatInfoForm.occupation"
											:disabled="!isProfileEdit"
										/>
									</b-col>
								</b-row>
							</div>
							<div class="form-group mb-4">
								<label for="updateName-input">Dream&Goals</label>
								<input
											type="text"
											class="form-control"
											id="updateName-input"
											placeholder="Dream&Goals"
											v-model="chatInfoForm.dream"
											:disabled="!isProfileEdit"
								/>
							</div>
							<div>
								<button
									v-if="isProfileEdit"
									class="w-25 btn btn-primary btn-block"
									type="submit"
								>Save</button>
							</div>
						</form>
					</div>
				</div>
				<!-- end user-profile-desc -->
			</div>
		</div>
		<div v-else>
			<div class="card" style="margin-top: 120px;">
				<div class="card-body p-4">
					<div class="text-center px-4">
						<h2>Elevate Your Experience with Uninterrupted Chatting!</h2>
					</div>
					<div class="text-center pt-4">
						<p>Upgrade your plan now to enjoy uninterrupted chatting pleasure.</p>
					</div>
					<div class="text-center pt-2">
						<b-button variant="secondary" @click="unlockCurrentChat">Unlock this chat</b-button>
					</div>
				</div>
			</div>
		</div>
		<b-modal
			v-model="showModal"
			title="Do you want to delete a chat?"
			hide-footer
		>
			<div class="d-flex align-items-center justify-content-between">
				<b-button
					variant="outline-danger"
					class="w-25 btn btn-block"
					@click="showModal = false"
				>Cancel</b-button>
				<b-button
					variant="primary"
					class="w-25 btn btn-primary btn-block"
					@click="deleteChat"
				>Delete</b-button>
			</div>
		</b-modal>
	</div>
</template>

<style scoped lang="scss">
#mess-end{
	width: 200px;
	height: 50px;
	display: block;
}
</style>